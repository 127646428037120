.header-title {
    font-size: 32px;
    color: #5F24DB;
    font-weight: 600;
    font-family: 'Public Sans', sans-serif;
}

.header-subtitle {
    font-size: 16px;
    color: #9E9E9E;
    font-weight: 500;
    font-family: 'Public Sans', sans-serif;
}