// change the theme
$theme-colors: (
  "primary": #6f12e9,
//   "secondary": #f3f3f3,
  // "primary": #6f12e9,
  // 6f12e9
//   "danger": #ff4136
);

html {
  overflow-y:scroll;
  height: 100vh;
}

.modal-open[style] {
  padding-right: 0px !important;
  overflow: visible !important;
  }
  
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


*, *::before, *::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	// width: 400px;
	// height: 300px;
	border-radius: 10px;
	overflow: hidden;
	// padding: 2rem;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 4px;
		top: 4px;
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		background: inherit;
		border-radius: 5px;
	}
}

#root {
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	min-height: 100vh;
	// margin-top: 57px;
}
