.likedPage-activityTypeDropdownEmojiButtonBase {
    border-radius: 20px;
}

.likedPage-deleteIcon.MuiSvgIcon-root {
    width: 22px;
    height: 22px;
}

.likedPage-deleteIconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0px;
}

.likedPage-dropdownIconContainer {
    padding-left: 0px;
}