/* :root {
    --primary_color: #6f12e9;
    --secondary_color: #242424;
    --text_color_primary: #fff;
    --text_color_secondary: #000;
} */

.guide-header {
    /* background: var(--primary_color); */
    /* background: linear-gradient(var(--primary_color), var(--secondary_color)); */
    /* background: linear-gradient(var(--primary_color), rgb(247, 247, 250)); */
    background: linear-gradient(rgb(247, 247, 250), rgb(247, 247, 250));
    /* color: var(--text_color_primary); */
    /* border-radius: 1rem; */
}

#guide-page {
    overflow-x: hidden;
}

.guide-thumbnail {
    border-radius: 7%;
    width: 200px;
    height: 200px;
}

.guide-backarrowIcon.MuiSvgIcon-root {
    width: 33px;
    height: 33px;
    cursor: pointer;
}

.guide-control-bar-container {
    text-align: center;
    background: var(--secondary_color);
}

.guide-control-bar {
    background: var(--primary_color);
    color: var(--text_color_primary);
}

.guide-control-bar.on {
    background: var(--primary_color) !important;
    color: var(--text_color_primary);
}

.guide-control-bar.off {
    background: var(--secondary_color);
    color: var(--text_color_secondary);
}

.guide-categories-container {
    padding-top: 1rem;
    background: var(--secondary_color);
    color: var(--text_color_secondary);
}

.guide-categories-expand-icon {
    color: var(--text_color_secondary);
}

.guide-fab {
    margin: 0px !important;
    top: auto !important;
    right: 20px !important;
    bottom: 20px !important;
    left: auto !important;
    position: fixed !important;
    background-color: var(--primary_color) !important;
    color: var(--text_color_primary) !important;
    font-family: inherit !important;
    /* text-transform: capitalize !important; */
}

.guide-fab-map {
    margin: 0px !important;
    top: 20px !important;
    right: auto !important;
    bottom: auto !important;
    left: 20px !important;
    position: fixed !important;
    background-color: var(--primary_color) !important;
    color: var(--text_color_primary) !important;
    font-family: inherit !important;
    /* text-transform: capitalize !important; */
}


@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.likedPage-container {
    background-color: rgb(247, 247, 250);
    width: 100%;
    padding: 14px;
    padding-top: 35px;
    overflow-x: hidden;
}

html {
    height: calc(100% - 95px);
    width: 100%;
}

body,
#root,
.likedPage-container {
    height: 100%;
}

h2.likedPage-cityText {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Public Sans', sans-serif;
    color: #9E9E9E;
    margin-left: 3px;
}

h1.likedPage-title {
    font-size: 26px;
    font-weight: 600;
    font-family: 'Public Sans', sans-serif;
}

.likedPage-editIcon {
    width: 25px;
    height: 25px;
    color: #adadad;
}

.likedPage-analyticsIcon {
    width: 25px;
    height: 25px;
    color: #adadad;
}

.row {
    display: flex;
    flex-direction: row;
}

.likedPage-titleAndEditContainer {
    width: fit-content;
    margin-left: -15px;
}

.MuiTypography-root {
    font-family: 'Public Sans', sans-serif !important;
} 

.likedPage-titleEditAndMapContainer {
    justify-content: space-between;
    margin-left: 5px;
}

button.likedPage-mapFab {
    /* position: sticky;
    top: 20px; */
    width: 58px;
    height: 58px;
    background-color: #5F24DB;
}
.guidePage-mapButtonCol {
    align-items: center;
    display: flex;
    width: fit-content;
    width: -moz-fit-content;
    padding-right: 5px;
    padding-left: 5px;
}

.guidePage-mapButtonRow {
    display: flex;
    flex-direction: row;
    margin-left: -7px;
}

.guidePage-mapButton.MuiButton-containedPrimary {
    border-radius: 20px;
    margin-top: 10px;
    font-size: 16px;
}

.guidePage-shareButton.MuiButton-outlinedPrimary {
    border-radius: 20px;
    margin-top: 10px;
    font-size: 16px;
    color: #686868;
    border-color: #828282;
}

button.likedPage-mapFab:hover {
    background-color: #5F24DB;
}

.likedPage-mapIcon {
    width: 32px;
    height: 32px;
}

.likedPage-filterChip.MuiChip-root {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Public Sans', sans-serif;
    margin-top: 13px;
    width: fit-content;
    margin-left: 10px;
}

.likedPage-filterChip.MuiButtonBase-root {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Public Sans', sans-serif;
    margin-top: 13px;
    width: fit-content;
    margin-left: 10px;
}

.likedPage-chipRowContainer {
    margin-left: 9px;
    margin-right: 20px;
    display: block;
    display: inline-flex;
}

.likedPage-chipRowScrollContainer {
    margin-right: -20px;
    margin-left: -20px;
}

.likedPage-activityTypeDropdown {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.likedPage-activityTypeDropdownTitle {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Public Sans', sans-serif;
    color: #686868;
    margin-top: 5px;
}

.likedPage-dropdownIcon.MuiSvgIcon-root {
    width: 33px;
    height: 33px;
}

.likedPage-activityTypeDropdownTitleEmoji {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    margin-bottom: 4px;
}

.likedPage-loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90%;
}

.likedPage-loader > .MuiCircularProgress-svg {
    color: #5F24DB;
}

.likedPage-loadingText {
    margin-top: 30px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Public Sans', sans-serif;
    color: #a1a1a1;
    text-align: center;
    margin-right: 15%;
    margin-left: 15%;
}

.likedPage-cardHeaderAndEditContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.likedPage-cardHeaderAndEditContainer > .MuiCardHeader-root {
    max-width: fit-content;
}

.likedPage-editIconButton.MuiIconButton-root {
    margin-left: 10px;
}

.likedPage-analyticsIconButton.MuiIconButton-root {
    margin-left: 0px;
}

.guidePage-filterChipRowTitle {
    font-size: 15px;
    margin-bottom: -10px;
    margin-top: 15px;
    margin-left: 2px;
    font-weight: 600;
    color: #686868;
}