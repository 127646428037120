@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .1s ease !important;
    -o-transition: height .1s ease !important;
    transition: height .1s ease !important;
}

.explore-scrollable-row {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0px calc(var(--bs-gutter-x) * -0.5);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.explore-scrollable-row::-webkit-scrollbar {
    display: none;
}

.explore-scrollable-row>.guide-card-wrapper {
    display: inline-block;
    float: none;
    margin: 0px 0px 0px calc(var(--bs-gutter-x) * 0.5);
    margin-bottom: 5px;
}

.explorePage-cityTitle {
    font-size: 24px;
    color: #696969;
    font-weight: 600;
    font-family: 'Public Sans', sans-serif;
}

.explorePage-container {
    padding-bottom: 95px;
}

.explorePage-loginButton.MuiButton-filled {
    background-color: #5F24DB;
    color: #fff;
    height: 40px;
    font-weight: 500;
    padding: 6px 20px;
    border-radius: 35px;
    text-transform: unset;
}

.explorePage-loginButton.MuiButton-filled:active {
    background-color: #fff;
    color: #5F24DB;
}

.explorePage-plusIconButton {
    margin-right: 10px;
}